<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: fromBranchPage?'Branches':'StaffList',
              }"
              >{{ fromBranchPage ? '分店管理' : '帳號管理' }}</b-breadcrumb-item
            >
            <b-breadcrumb-item
              :to="{
                name: fromBranchPage?'BranchStaffs':'StaffList',
                params: {
                  branchID: this.$route.params.branchID,
                }
              }"
              >{{ fromBranchPage ? '店內帳號' : '帳號列表' }}</b-breadcrumb-item
            >
            <b-breadcrumb-item active>設定所屬分店</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-overlay :show="showLoading">
      <b-card>
        <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
          <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
            設定所屬分店
          </h4>
        </div>
        <b-card-body>
          <div class="row">
            <div class="col-12 col-xl-8 mt-3">
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="姓名"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="text"
                  v-model="staff.name"
                  disabled
                ></b-form-input
              ></b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="員工編號"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="text"
                  v-model="staff.employee_code"
                  disabled
                ></b-form-input
              ></b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="所屬分店"
              >
                <div v-for="(branch, index) in branches" :key="branch.id" class="mb-2 d-flex">
                  <b-input-group class="col-7 pl-0">
                    <b-form-select
                      class="form-control"
                      v-model="branch.id"
                    >
                      <b-form-select-option :value="null" disabled
                      >請選擇分店
                      </b-form-select-option
                      >
                      <b-form-select-option
                        v-for="(branchOption, index) in branchOptions"
                        :key="index"
                        :value="branchOption.id"
                      >{{ displayBranchName(branchOption) }}
                      </b-form-select-option
                      >
                    </b-form-select>
                    <b-input-group-text class="text-danger" slot="append">
                      <span @click="removeBranch(index)">刪除</span>
                    </b-input-group-text>
                  </b-input-group>
                  <b-form-group
                    label-cols="4"
                    label-size="sm"
                    label="店內順序"
                    class="mb-0"
                  >
                    <b-form-input
                      class="mb-2 mr-sm-2 mb-sm-0"
                      type="number"
                      min=1
                      v-model="branch.order"
                      @change="orderChanged"
                    ></b-form-input
                  ></b-form-group>
                </div>

                <b-button class="btn-inverse-primary" @click="addBranch">新增</b-button>
              </b-form-group
              >
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-12">
                  <div class="float-right">
                    <b-button
                      class="mr-3"
                      @click="handleCancel"
                      variant="outline-danger"
                      >返回</b-button
                    >
                    <b-button @click="submit" variant="success">更新</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import staffApi from "@/apis/staff";
import branchApi from "@/apis/branch";
import {mapState, mapGetters} from 'vuex';

export default {
  computed: {
    ...mapState('auth', {
      user: state => state.user,
    }),
    ...mapGetters('general', [
      'myBranches',
      'organization'
    ]),
    fromBranchPage() {
      return !!this.$route.params.branchID;
    },
    branchOptions() {
      return this.allBranches
    },
  },
  data() {
    return {
      staff: {
        name: null,
        employee_code: null,
      },
      showLoading: true,
      branches: [],
      allBranches: [],
    };
  },
  mounted() {
    this.fetchStaff();
    if (this.$permissions.has(this.$permissions.consts.BRANCH_TOP_VIEW)) {
      this.fetchAllBranches();
    } else {
      this.allBranches = this.myBranches.sort((a, b) => {
        if (a.level === b.level) {
          return a.order - b.order;
        }
        return a.level - b.level;
      })
    }
  },
  methods: {
    async fetchAllBranches() {
      const { data } = await branchApi.getBranches(this.organization, { is_all: true });
      this.allBranches = data.data.sort((a, b) => {
        if (a.level === b.level) {
          return a.order - b.order;
        }
        return a.level - b.level;
      });
    },
    orderChanged() {
      this.branches.forEach(branch => {
        if (branch.order == null || branch.order == '') {
          return
        }
        if (branch.order <= 0) {
          branch.order = 1
        }
      })
    },
    async fetchStaff() {
      const staff_id = this.$route.params.staff_id;

      let response = await staffApi.getOneStaff(staff_id);
      if (response.status === 200 && response.data.data) {
        this.staff = response.data.data;
        this.branches = this.staff.branches.filter(branch => {
          return branch.org_id == this.organization.id;
        })
        this.branches.forEach(branch => {
          branch.order = branch.pivot.order
        })
      } else {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "取得帳號資料失敗",
        });
      }
      this.showLoading = false;
    },
    async submit() {
      this.showLoading = true;

      try {
        let response = await staffApi.setBranches(
          this.$route.params.staff_id,
          { branches: this.branches }
        );
        if (response.status && response.status === 200) {
          this.goListPage();
        }
      } catch (error) {
        console.error(error);
        alert("設定所屬分店失敗");
      }

      this.showLoading = false;
    },
    goListPage() {
      if (this.fromBranchPage) {
        this.$router.push({name: "BranchStaffs"});
      } else {
        this.$router.push({name: "StaffList"});
      }
    },
    handleCancel() {
      this.goListPage();
    },
    addBranch() {
      this.branches.push({ id: null, order: null });
    },
    removeBranch(index) {
      this.branches.splice(index, 1);
    },
    displayBranchName(branch) {
      if (branch.level > 1) {
        return '-'.repeat(branch.level - 1) + ` ${branch.name} ${branch.branch_code}`
      }
      return `${branch.name} ${branch.branch_code}`
    }
  },
};
</script>

<style>
.table-responsive {
  min-height: 300px;
}
</style>
